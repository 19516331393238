import { useSubscription } from '@rta/rta-blueprint-library';
import { BiHide, BiShow } from 'react-icons/bi';
import { useEffect, useState } from 'react';

import { Controller } from '@context';
import { ControllerShowAllDimensionsSupport } from './types';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '@features';

type ShowAllDimensionsFeatureParams = FeatureHoookParams;
export const useShowAllDimensions = (_params: ShowAllDimensionsFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, ControllerShowAllDimensionsSupport>();

	// Subscribe to dimension updates
	const [update] = useSubscription(controller, false, [['designer-options-show-all-dimensions', 'updated']]);

	const controllerSupportsShowAllDimensionsFeature = (controller?.options?.showAllDimensions ?? null) !== null;

	const [disabled, tooltip] = (() => {
		if (!controllerSupportsShowAllDimensionsFeature) return [true, lang.dimensions.tooltip.disabledNotSupportCurrentView];
		return [
			false,
			controller?.options.showAllDimensions
				? lang.dimensions.tooltip.hideHiddenDimensions
				: lang.dimensions.tooltip.showHiddenDimensions
		];
	})();
	const [value, setValue] = useState<boolean>(disabled ? false : (controller?.options?.showAllDimensions ?? false));

	useEffect(() => {
		if (controller?.options) {
			setValue(controller?.options?.showAllDimensions ?? false);
		}
	}, [controller, update]);

	return {
		name: 'showAllDimensionsFeature',
		label: lang.dimensions.label.showAllDimensions,
		shortcut: '',
		tooltip,
		icon: (props = {}) => {
			return disabled || !value
				? <BiHide {...props} />
				: <BiShow {...props} />;
		},
		disabled,
		value,
		hidden: false,
		handler: () => {
			if (controller && controllerSupportsShowAllDimensionsFeature) {
				controller.options.showAllDimensions = !controller?.options?.showAllDimensions ?? false;
			}
		}
	};
};
