import { actionPanels } from './action-panels';
import { app } from './app';
import { blueprintStatus } from './blueprint-status';
import { contextPanels } from './context-panels';
import { crmLang } from './crm.lang';
import { customerLang } from './customer.lang';
import { debug } from './debug';
import { dimensions } from './dimensions';
import { document } from './document';
import { fontSize } from './fontsize';
import { islandSubmission } from './island-submission';
import { layer } from './layer';
import { navigation } from './navigation';
import { promotionalSku } from './promotional-sku';
import { social } from './social';
import { view } from './view';
import { zoom } from './zoom';

export const lang = {
	actionPanels,
	app,
	blueprintStatus,
	contextPanels,
	crm: crmLang,
	debug,
	dimensions,
	document,
	fontSize,
	zoom,
	view,
	layer,
	social,
	navigation,
	promotionalSku,
	islandSubmission,
	customer: customerLang
};
