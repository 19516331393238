/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines-per-function */
import { useDispatch } from 'react-redux';
import { Box, FormLabel, Select, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Components, EventCrudMessage, PanelItem, ReferenceMap } from '@rta/rta-blueprint-library';
import { useEffect, useRef, useState } from 'react';

import { ContextPanelContent } from './context-panel-content';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { InlineNumberInput, InlineSlider, StackedStringInput } from './controls';

interface CustomAppliancePropertiesPanelProps {
	id: string | null;
}

export const CustomAppliancePropertiesPanel: React.FC<CustomAppliancePropertiesPanelProps> = (props: CustomAppliancePropertiesPanelProps): JSX.Element => {
	const { id } = props;

	const colorSectionHeader = useColorModeValue('gray.600', 'gray.300');

	const [controller] = useDesign();
	const kitchen = controller?.kitchen ?? null;

	const [gasChoice, setGasChoice] = useState<string>('');
	const [panelItem, setPanelItem] = useState<PanelItem | null>(null);

	useEffect(() => {
		if (controller) {
			const locator = id ? kitchen?.getReferenceMap(id) ?? null : null;
			const pi: PanelItem | null = locator?.panelItem ?? null;

			setPanelItem(pi);
		}
	}, [controller, id]);

	if (!controller || !panelItem || !kitchen) return <></>;

	const title = lang.contextPanels.title.customApplianceProperties;

	return <ContextPanelContent title={title}>
		<Stack width="100%" pt="10px" spacing={'5px'}>
			<StackedStringInput
				label="Sku"
				placeholder="Sku code..."
				value={panelItem.item.component?.label ?? ''}
				onChange={((e) => {
					if (panelItem.item.component) panelItem.item.component.label = e.target.value;
					console.log('label', panelItem.item.component?.label, panelItem.item.component?.sku);
				})}
			/>
			<StackedStringInput
				label="Description"
				placeholder="Appliance description..."
				value={panelItem.item.component?.description ?? ''}
				onChange={((e) => {
					if (panelItem.item.component) panelItem.item.component.description = e.target.value;
				})}
			/>

			<Box>
				<FormLabel htmlFor="vendor" fontSize="small" marginBottom="0">Vendor:</FormLabel>
				<Select id="vendor" height="2em"
					fontSize="small"
					value={panelItem.item.component?.vendorId ?? ''}
					onChange={(e) => {
						if (panelItem.item.component) {
							panelItem.item.component.vendorId = e.target.value;
						}
					}}
				>
					<option value="">Other</option>
					{ kitchen.context.vendors
						.filter((v) => v.active)
						.map((v, i) => <option key={i} value={v.id}>{v.displayValue}</option>)}
				</Select>
			</Box>

			<Box>
				<FormLabel htmlFor="appliance-type" fontSize="small" marginBottom="0">Type:</FormLabel>
				<Select id="appliance-type" height="2em"
					fontSize="small"
					value={panelItem.item.component?.categoryId ?? ''}
					onChange={(e) => {
						if (panelItem.item.component) panelItem.item.component.categoryId = e.target.value;
					}}
				>
					{ kitchen.context.categories
						.filter((c) => c.active)
						.map((cat, i) => <option key={i} value={cat.id}>{cat.description}</option>)}
				</Select>
			</Box>

			<Box>
				<FormLabel htmlFor="appliance-placement" fontSize="small" marginBottom="0">Placement:</FormLabel>
				<Select id="appliance-placement" height="2em"
					fontSize="small"
					value={panelItem.item.component?.placement ?? ''}
					onChange={(e) => {
						if (panelItem.item.component) panelItem.item.component.placement = e.target.value;

						panelItem.item.component.distanceBelowCountertop = panelItem.item.component.placement === Components.PLACEMENT_FRONT_AND_TOP
							? panelItem.item.component.cutout.height
							: 0;

						if (panelItem.item.component.placement === Components.PLACEMENT_TOP_ONLY) {
							panelItem.item.component.distanceFrontOfFace = null;
							panelItem.center.z = 0;
						} else if (panelItem.item.component.distanceFrontOfFace === null) {
							panelItem.item.component.distanceFrontOfFace =
								panelItem.item.component.placement === Components.PLACEMENT_FRONT_AND_TOP
									? 0
									: null;
						}
					}}
				>
					{ Components.placement.map((p, i) => {
						return <option key={i} value={p.key}>{p.displayName}</option>;
					})}
				</Select>
			</Box>

			<Box>
				<FormLabel htmlFor="appliance-gas-choice" fontSize="small" marginBottom="0">Fuel Options:</FormLabel>
				<Select id="appliance-gas-choice" height="2em"
					fontSize="small"
					value={gasChoice}
					onChange={(event) => setGasChoice(event.target.value)}
				>
					<option value="">Use Kitchen Default</option>
					<option value="CH">Charcoal</option>
					<option value="EL">Electric</option>
					<option value="PE">Pellet</option>
					<option value="LP">Propane</option>
					<option value="NG">Natural Gas</option>
				</Select>
			</Box>

			<Box marginBottom={3} padding={2} textAlign="left" borderLeft="1px solid grey">
				<Text paddingBottom={3} fontSize="medium">Dimensional Info.</Text>
				<Stack spacing="3" width="100%" height="65%">
					<InlineNumberInput
						category="cutout"
						label="Cutout Width"
						min={0.5}
						value={panelItem.item.cutout.width}
						onChange={(e) => { panelItem.item.cutout = { width: e }; }}
					/>
					<InlineNumberInput
						category="cutout"
						label="Cutout Height"
						min={0.5}
						value={panelItem.item.cutout.height}
						onChange={(e) => { panelItem.item.cutout = { height: e }; }}
					/>
					<InlineNumberInput
						category="cutout"
						label="Cutout Depth"
						min={0}
						value={panelItem.item.cutout.depth}
						onChange={(e) => { panelItem.item.cutout = { depth: e }; }}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Width" min={0.5}
						value={panelItem.item.size.width}
						onChange={(e) => { panelItem.item.size = { width: e }; }}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Height" min={0.5}
						value={panelItem.item.size.height}
						onChange={(e) => { panelItem.item.size = { height: e }; }}
					/>
					<InlineNumberInput
						category="appliance" label="Overall Depth" min={0}
						value={panelItem.item.component.size.depth}
						onChange={(e) => { panelItem.item.size = { depth: e }; }}
					/>
					<InlineNumberInput
						category="appliance" label="Distance Below Counter" min={0}
						value={ panelItem.item.component.distanceBelowCountertop ?? 0 }
						enabled={false}
					/>
					<InlineNumberInput
						category="appliance" label="Distance Front Of Face" min={0}
						enabled={panelItem.item.component.placement === Components.PLACEMENT_FRONT_AND_TOP}
						value={ panelItem.item.component.distanceFrontOfFace }
						onChange={(e) => {
							panelItem.item.component.distanceFrontOfFace = panelItem.item.component.placement === Components.PLACEMENT_FRONT_AND_TOP
								? e
								: null;
						}}
					/>
				</Stack>
			</Box>

			{ panelItem &&
				<Box>
					<Text pt="5px" pl="5px" color={colorSectionHeader} fontSize="medium"
						fontWeight="medium"
					>Dimensional Details</Text>
					<Box mb={3} padding={2} textAlign="left"
						borderLeft="1px solid grey" borderTop="1px" roundedTopLeft="7px"
					>
						<Stack spacing="3" width="100%" height="65%">
							<InlineSlider
								category="dimension" label="H. Line"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.hLine }
								onChange={(e) => {
									panelItem.dimensionOffsets = { hLine: e };
								}}
							/>
							<InlineSlider
								category="dimension" label="H. Label"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.hLabel }
								onChange={(e) => {
									panelItem.dimensionOffsets = { ...panelItem.dimensionOffsets, hLabel: e };
								}}
							/>
							<InlineSlider
								category="dimension" label="V. Line"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.vLine }
								onChange={(e) => {
									panelItem.dimensionOffsets = { ...panelItem.dimensionOffsets, vLine: e };
								}}
							/>
							<InlineSlider
								category="dimension" label="V. Label"
								min={0} max={1} step={0.01}
								value={ panelItem.dimensionOffsets.vLabel }
								onChange={(e) => {
									panelItem.dimensionOffsets = { vLabel: e };
								}}
							/>
						</Stack>
					</Box>
				</Box>
			}
		</Stack>
	</ContextPanelContent>;
};
