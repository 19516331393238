import { LuArrowDown } from 'react-icons/lu';
import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { ControllerFontSizeSupport } from './types';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

type DecreaseFontSizeFeatureParams = FeatureHoookParams;
export const useDecreaseFontSizeFeature = (_params: DecreaseFontSizeFeatureParams = {}): Feature<number> => {
	const [controller] = useDesign<Controller, ControllerFontSizeSupport>();

	useSubscription(controller, false, [['designer-options-adjust-font-size', 'updated']]);

	const controllerSupportsFontSizeFeature = (controller?.options?.dimensions?.fontSizeFactor ?? null) !== null;

	const font = controller && controllerSupportsFontSizeFeature ? controller.options.dimensions.fontSizeFactor : 0;

	return {
		name: 'decreaseFontSize',
		label: lang.fontSize.label.decrease,
		shortcut: '',
		tooltip: lang.fontSize.tooltip.decrease,
		icon: (props = {}) => <LuArrowDown {...props} />,
		disabled: !controllerSupportsFontSizeFeature,
		value: font ?? 0,
		hidden: false,
		handler: () => {
			if (controller && controllerSupportsFontSizeFeature) controller.options.dimensions.fontSizeFactor -= 0.1;
		}
	};
};
