/* eslint-disable react/jsx-no-undef */
/* eslint-disable max-lines-per-function */
import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { Island, useSubscription } from '@rta/rta-blueprint-library';
import { Box, Divider, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import { FaB, FaF, FaL, FaR, FaT } from 'react-icons/fa6';

import { ControllerDisplayModeSupport } from '@features/navigation/types';
import { RtaFeatureIconButton } from '@components';
import { useDesign } from '@hooks';
import { Feature, showPanelFeature, useDecreaseFontSizeFeature, useDisplayManufacturingModeFeature, useDisplayOverheadModeFeature, useIncreaseFontSizeFeature, useShowAllDimensions } from '@features';

// eslint-disable-next-line max-statements
export const ActionMenuBar = () => {
	const design = useDesign();
	const controller = design[0] as (typeof design[0]) & ControllerDisplayModeSupport;

	useSubscription(controller, false, [['designer-options-adjust-font-size', 'updated']]);

	const showAllDimensions = useShowAllDimensions();
	const increaseFontSize = useIncreaseFontSizeFeature();
	const decreaseFontSize = useDecreaseFontSizeFeature();
	const displayOverheadModeFeature = useDisplayOverheadModeFeature();
	const manufacturingModeFeature = useDisplayManufacturingModeFeature();

	let island: Island | null = null;
	const navFeature: Record<string, Feature<unknown>> = {};
	if (controller?.instanceOf === 'bp-controller' && (controller as BpController).navigation?.islandId) {
		island = controller.kitchen.design.islands.findById((controller as BpController).navigation?.islandId as string);
		if (island) navFeature.countertop = showPanelFeature({ controller, label: 'countertop', Icon: FaT });

		island?.panels.children.forEach((panel) => {
			const icon = {
				'front': FaF,
				'back': FaB,
				'back left': FaL,
				'back right': FaR,
				'left': FaL,
				'right': FaR
			}[panel.label];
			navFeature[panel.label] = showPanelFeature({ controller, panelId: panel.id, label: panel.label, Icon: icon });
		});
	}

	return (
		<Box w={'calc(100% - 2px)'} h={'calc(100% - 2px)'} m={'1px'}>
			<Grid templateRows={'5px 1fr 5px 70px'} w={'100%'} h={'100%'} gap={'0'}>
				<GridItem><Divider orientation={'vertical'} /></GridItem>
				<GridItem border="0px">
					<VStack>
						<RtaFeatureIconButton feature={displayOverheadModeFeature} />
						<RtaFeatureIconButton feature={manufacturingModeFeature} />

						<Divider orientation={'horizontal'} />
						{
							Object.keys(navFeature).map((key, i) => (
								<RtaFeatureIconButton key={i} feature={navFeature[key]} iconProps={{ fontSize: '.8em' }} />
							))
						}
						<Divider h={'2px'} my={'2px'} orientation={'horizontal'}/>

						<RtaFeatureIconButton feature={showAllDimensions} />
						<Divider h={'2px'} my={'2px'} orientation={'horizontal'}/>
						<RtaFeatureIconButton feature={increaseFontSize} />
						<Text fontSize=".5em">{Math.floor(increaseFontSize.value * 100) / 100}</Text>
						<RtaFeatureIconButton feature={decreaseFontSize} />
						<Divider h={'2px'} my={'2px'} orientation={'horizontal'}/>
					</VStack>
				</GridItem>


				{/* <GridItem><Divider orientation={'horizontal'} /></GridItem>
				<GridItem border="0px">
					<VStack>
						<RtaIconButton icon={<FaDrawPolygon />} label="Designer"
							onClick={() => dispatch(setContentPanelMode(ContentPanelMode.Designer))}
						/>
						<RtaIconButton icon={<SiBlueprint />} label="3D Viewer"
							onClick={() => dispatch(setContentPanelMode(ContentPanelMode.ThreeD))}
						/>
					</VStack>
				</GridItem> */}
			</Grid>
		</Box>
	);
};
