/* eslint-disable max-lines-per-function */
import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { Box, Checkbox, FormLabel, Select, Stack, Text } from '@chakra-ui/react';
import { COUNTERTOP_MINIMUM_OVERHANG, Countertops, Island, Kitchen, useSubscription } from '@rta/rta-blueprint-library';
import { useEffect, useState } from 'react';

import { ContextPanelContent } from './context-panel-content';
import { displayDropdowns } from './helpers';
import { InlineNumberInput } from './controls';
import { lang } from '@lang';
import { useDesign } from '@hooks';

export type CountertopPropertiesPanelProps = {
	id: string | null;
};

export const CountertopPropertiesPanel = ({ id }: CountertopPropertiesPanelProps): JSX.Element => {
	const [controller] = useDesign<BpController>();

	const [island, setIsland] = useState<Island | null>(null);
	const [kitchen, setKitchen] = useState<Kitchen | null>(null);

	useSubscription(controller?.kitchen ?? null, false, [
		['countertop.*', '*'],
		['panel.*', '*']
	]);

	useEffect(() => {
		if (controller && id) {
			const refMap = controller?.kitchen?.getReferenceMap(id);
			setIsland(refMap.island);
			setKitchen(refMap.kitchen);
		} else if (controller) {
			setIsland(null);
			setKitchen(controller.kitchen);
		}
	}, [controller, id]);

	if (!kitchen) return <></>;

	const countertopAssetData = Countertops.assetData;
	const countertop = island ? island.getCountertop().material : kitchen?.material.countertop ?? null;
	// console.log('render:countertop-properties-panel', id, { countertop, island });

	return <ContextPanelContent title={lang.contextPanels.title.countertopProperties}>
		<>
			<Box marginTop="5px" marginBottom={3} padding={2} textAlign="left" borderLeft="1px solid grey" borderBottom="1px solid darkgrey">
				<Stack spacing="2" width="100%" height="65%">
					<Box>
						{ island &&
							<Checkbox id="countertop-material-override" defaultChecked={ countertop?.override }
								onChange={(e) => {
									if (countertop) {
										countertop.override = e.target.checked;
									}
								}}>
								<Text fontSize="small">Override Kitchen Settings</Text>
							</Checkbox>
						}
						{ countertop?.override
							? <Text fontSize="x-small">The Countertop setting shown here only apply to the selected countertop.</Text>
							: <Text fontSize="x-small">The countertop setting shown here apply to all kitchen countertops.</Text>
						}
					</Box>
					<Box>
						<FormLabel htmlFor="countertop-material" marginBottom={0} fontSize="small">Material:</FormLabel>
						<Select id="countertop-material" placeholder="Countertop" fontSize="small"
							value={countertop?.key}
							onChange={(e) => {
								if (countertop) {
									countertop.setMaterial(e.target.value);
								}
							}}>
							{displayDropdowns(countertopAssetData)}
						</Select>
					</Box>
					<Box>
						<FormLabel htmlFor="countertop-thickness" marginBottom={0} fontSize="small">Thickness:</FormLabel>
						<Select id="countertop-thickness" fontSize="small"
							value={countertop?.thickness}
							onChange={(e) => {
								if (countertop) {
									countertop.thickness = parseFloat(e.target.value);
								}
							}}>
							<option value="0.5">0.5&quot;</option>
							<option value="0.75">0.75&quot;</option>
							<option value="1.25">1.25&quot;</option>
							<option value="2">2&quot;</option>
						</Select>
					</Box>
					<Box>
						<FormLabel htmlFor="countertop-edge" marginBottom={0} fontSize="small">Edge:</FormLabel>
						<Select id="countertop-edge" placeholder="Edge" fontSize="small"
							value={countertop?.edge ? countertop?.edge : 'na'}
							disabled
							// onChange={(_e) => {
							// 	if (countertop) {
							// 		// Edge is read only property
							// 		// countertop.material.edge = e.target.value
							// 	}
							// }}
						>
							<option value="na">Not Applicable</option>
							<option value="clean">Clean</option>
							<option value="chiseled">Chiseled</option>
						</Select>
					</Box>
				</Stack>
			</Box>

			{ island &&
				<Box marginBottom={3} padding={2} textAlign="left" borderLeft="1px solid grey" borderBottom="1px solid darkgrey">
					<Text paddingBottom={3} fontSize="medium">Overhangs</Text>
					<Stack spacing="3" width="100%" height="65%">
						{ island.panels.children.map((panel, i) => {
							return <InlineNumberInput key={i}
								category="overhangs" label={panel.label}
								min={COUNTERTOP_MINIMUM_OVERHANG}
								value={ panel.countertopOverhang }
								onChange={(valueString) => {
									if (panel) {
										panel.countertopOverhang = parseFloat(valueString);
									}
								}}
							/>;
						})};
					</Stack>
				</Box>
			}
		</>
	</ContextPanelContent>;
};
