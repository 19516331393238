import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { Feature, FeatureHoookParams } from '..';

interface IControllerSupportsFeature { options: {
	showAppliances: boolean;
	showApplianceDimensions: boolean;
	showCutouts: boolean;
}}

type ApplianceDimensionFeatureParams = FeatureHoookParams;
export const useShowApplianceDimensionsFeature = (_params: ApplianceDimensionFeatureParams = {}): Feature<boolean> => {
	const [controller] = useDesign<Controller, IControllerSupportsFeature>();

	useSubscription(controller, true, [['layer-option-appliance-dimensions', '*']]);
	useSubscription(controller, true, [['layer-option-cutout', '*']]);
	useSubscription(controller, true, [['layer-option-appliance', '*']]);

	const controllerSupportsFeature = (controller?.options?.showApplianceDimensions ?? null) !== null;
	const showDimensions = controller?.options?.showApplianceDimensions ?? true;
	const showAppliances = controller?.options?.showAppliances ?? true;
	const showCutouts = controller?.options?.showCutouts ?? true;
	const disabled = (!controllerSupportsFeature) || !(showAppliances || showCutouts);
	const tooltip = (() => {
		const showHide = showDimensions ? lang.layer.tooltip.hide : lang.layer.tooltip.show;
		return `${showHide} ${lang.layer.label.applianceDimensions} ${lang.layer.layer}`;
	})();

	if (controller?.options?.showApplianceDimensions && disabled) controller.options.showApplianceDimensions = false;

	return {
		name: 'showApplianceDimension',
		label: lang.layer.label.applianceDimensions,
		shortcut: '',
		tooltip,
		icon: (props = {}) => <></>,
		disabled,
		value: showDimensions,
		hidden: false,
		handler: () => {
			if (controller?.options?.showApplianceDimensions) {
				controller.options.showApplianceDimensions = !showDimensions;
			}
		}
	};
};
