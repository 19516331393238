type ReleaseInfo = {
	release: string;
	build: string;
	commit: string;
}

const releaseInfo: ReleaseInfo = {
	release: '__RELEASE__',
	build: '20250120.9',
	commit: '33c6dc9'
};

export { releaseInfo };

