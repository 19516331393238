export const fontSize = {
	label: {
		increase: 'Increase Font Size',
		decrease: 'Decrease Font Size'
	},
	tooltip: {
		increase: 'Increase Font Size',
		decrease: 'Decrease Font Size'
	},
	message: {
	}
};
