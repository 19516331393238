export const dimensions = {
	label: {
		purgeDimensions: 'Purge Dimensions Settings',
		showAllDimensions: 'Show All Dimensions'
	},
	tooltip: {
		disabledNotSupportCurrentView: 'Purge Dimensions is not supported in the current view',
		disabledNotSupportCurrentContext: 'A design must be loaded to use this utility',
		disabledNothingToPurge: 'There are no dimensions to purge',
		purgeDimensions: 'Purge Dimensions',
		showHiddenDimensions: 'Show Hidden Dimensions',
		hideHiddenDimensions: 'Hide Hidden Dimensions'
	},
	message: {
	}
};
