import { ComponentController as BpController } from '@rta/rta-blueprint-component';
import { Kitchen3dController as TdController } from '@rta/rta-blueprint-3d-component';

import { Actions } from './actions';
import { ActionTypes } from './action-types';
import { saveDirtyKitchenLocally } from '@features/customer/helpers';
import { State } from './state.type';

export const reducer = (state: State, action: Actions): State => {
	let newState: State = {
		email: state.email,
		isLoading: state.isLoading,
		designs: [...state.designs]
	};
	// let bpState: DesignState | null = null;
	// let skipped = false;
	// console.log(`reducer:debug -> ${action?.type}`, { state, action });

	switch (action?.type) {
		case ActionTypes.addBlueprint:
			(() => {
				const bpController = new BpController(action.payload.blueprint, { eventGroup: `bp-${action.payload.blueprint.id}` });
				// create the edit version if version is latest
				const active = bpController.blueprint.kitchens.getActiveVersion();
				console.log('kv:addBlueprint', { active, version: action.payload.version, kitchens: bpController.blueprint.kitchens });
				bpController.kitchenVersion = active?.version === action.payload.version
					? null
					: action.payload.version;

				newState.designs.push({
					blueprint: action.payload.blueprint,
					isDirty: action.payload.isDirty,
					bpController,
					tdController: new TdController(action.payload.blueprint, { eventGroup: `td-${action.payload.blueprint.id}` })
				});

				bpController.options.readonly = false;
				bpController.options.contextMenu = true;
				bpController.options.assetsBaseUrl = process.env.REACT_APP_ASSETS_BASE_URL;
				bpController.options.autoZoom = null;
			})();

			break;
		case ActionTypes.clearCustomerDesigns:
			newState.email = null;
			newState.isLoading = false;
			newState.designs = [];
			break;
		case ActionTypes.deleteBlueprint:
			newState.designs = newState.designs.filter((bp) => bp.blueprint.id !== action.payload);
			break;
		case ActionTypes.loadBlueprintsStart:
			newState.designs = [];
			newState.isLoading = true;
			newState.email = action.payload;
			break;
		case ActionTypes.loadBlueprintsSuccess:
			newState.isLoading = false;
			break;
		case ActionTypes.loadBlueprintsFailure:
			newState.isLoading = false;
			newState.email = null;
			break;
		case ActionTypes.setIsDirty:
			(() => {
				const design = newState.designs.find((d) => d.blueprint.id === action.payload.id);
				if (design) {
					design.isDirty = action.payload.isDirty;
					if (design.isDirty && design.bpController) saveDirtyKitchenLocally(state.email, design.bpController);
				}
			})();
			break;
		default:
			// skipped = true;
			newState = state;
	}

	// console.log(`reducer:debug -> ${action?.type}`, { oldState: state, newState, action });
	// if (!skipped) console.log(`reducer:debug -> ${action.type}`, { state, newState });
	return newState;
};
