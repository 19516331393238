import { useSubscription } from '@rta/rta-blueprint-library';

import { Controller } from '@context';
import { Feature } from '..';
import { lang } from '@lang';
import { useDesign } from '@hooks';
import { selectNavigation, useAppSelector } from '@state';

export const usePurgeDimensionsFeature = (): Feature<boolean> => {
	const [controller] = useDesign<Controller>();
	const appNav = useAppSelector(selectNavigation);

	useSubscription(controller?.kitchen.design ?? null, false, [['dimensions', '*']]);

	const [disabled, tooltip] = (() => {
		if (appNav.designId === null) return [true, lang.dimensions.tooltip.disabledNotSupportCurrentContext];
		if (controller?.kitchen.design.dimensions.children.length === 0) return [true, lang.dimensions.tooltip.disabledNothingToPurge];
		return [false, lang.dimensions.tooltip.purgeDimensions];
	})();

	return {
		name: 'purgeDimensionsFeature',
		label: lang.dimensions.label.purgeDimensions,
		shortcut: '',
		tooltip,
		icon: () => <></>,
		disabled,
		value: !disabled,
		hidden: false,
		handler: () => {
			if (controller && controller.kitchen) {
				controller.kitchen.design.dimensions.purge();
			}
		}
	};
};
